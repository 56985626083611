import { atom, useAtomValue, useSetAtom } from 'jotai';

/**
 * Returns a flag indicating whether the audio is being shared by an internal FLOWN user (facilitator or flown roles).
 * This is used to determine whether to hide the tile that shows the screen or not
 */
const isInternalAudioShare = atom(false);
export const useSetIsInternalAudioShare = () =>
  useSetAtom(isInternalAudioShare);

export const useIsInternalAudioShare = () => useAtomValue(isInternalAudioShare);
