import {
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react';

import { FlownData } from '/flown/utils/user-data';

type UseFlownUserDataProps = {
  sessionId: string;
};
/**
 * userDataProp might be null. In a production setting, this is incredibly unlikely to happen, but in development mode,
 * React re-renders twice on purpose in order to expose these edge cases to you.
 * https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-double-rendering-in-
 * Note that the badge data is flattened by flown-com so it can be passed directly to the Badge component.
 */
export const useFlownUserData = ({ sessionId }: UseFlownUserDataProps) => {
  const userDataProp = useParticipantProperty(sessionId, 'userData');
  const userData = userDataProp ? (userDataProp as FlownData).c : null;

  return userData;
};

export const useLocalUserFlownUserData = () => {
  const localSessionId = useLocalSessionId();
  const userData = useFlownUserData({ sessionId: localSessionId });

  return userData;
};
